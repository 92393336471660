import type { JSX } from 'react';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

import { Banner } from '@/components';

export type BannerProps = SliceComponentProps<Content.BannerSlice>;

const BannerSlice = ({ slice }: BannerProps): JSX.Element => {
  return <Banner slice={slice} />;
};

export default BannerSlice;
